<template>
  <div class="mt-5">

    <!-- To-->
    <v-sheet
        :style="`border : 1px solid ${wsBACKGROUND}`"
        class=" mb-3 wsRoundedLight">

      <div class="pa-4 d-flex justify-space-between">
        <!-- icons and name-->
        <div class="d-flex">
          <v-icon size="20" :color="newsletter.total_receivers > 0 ? wsATTENTION : 'grey'" class="mr-3">mdi-account-multiple</v-icon>
          <div>
            <h4 style="font-size: 16px; font-weight: 600"  >{{ $t('newsletter.email.to') }}</h4>
            <h5 v-if="newsletter.total_receivers === 0" class="font-weight-light grey--text text--darken-3">
              {{$t('newsletter.email.toDescriptionEmpty') }}
            </h5>
            <div v-else>
              <h5  class="wei grey--text text--darken-3">
                {{  ($t('newsletter.email.toDescription') + ' : ' + newsletter.total_receivers ) }}
              </h5>
            </div>

          </div>
        </div>
        <!-- ::  Selectors and fields ::  -->
        <div>
          <v-btn @click="editReceivers = !editReceivers" rounded class="noCaps px-6 " text :color="wsACCENT">
            <v-icon v-if="!editReceivers" class="mr-2">mdi-pencil</v-icon>
            {{ editReceivers ? $t('Hide') : $t('Edit') }}
          </v-btn>
        </div>
      </div>

      <v-expand-transition>
        <div v-if="editReceivers">
          <div class="pa-4 pl-12 pr-10">

            <ws-data-table
                class="mt-5"
                v-if="newsletter.receivers_group.length > 0"
                :items="newsletter.receivers_group"
                :headers="headers"
                dense

            >
              <template #item.name="{item}">
                <h5>
                  <v-icon class="mr-2" :color="item.count ? wsATTENTION : 'grey'">{{ getGroupIcon(item.type) }}</v-icon>
                  {{item.name}}
                </h5>
              </template>
              <template #item.count="{item}">
                <h5 > {{ item.count }}</h5>
              </template>
              <template #item.delete="{item}">
                <v-btn @click="deleteReceiversGroup(item)" :color="wsACCENT" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>

            </ws-data-table>

            <div v-if="!checkFilters('all')">

              <h4>{{ $t('newsletter.email.receiver_groups.add_group') }}</h4>
              <!-- Receivers group Type-->
              <ws-select
                  v-model="entityDataReceivers.type"
                  @input="changeGroupType"
                  class="mt-5"
                  :placeholder="$t('Choose')"
                  :items="receiversGroupTypeSelect"
                  :label="$t('newsletter.email.receiver_groups.title')"
                  width="400px" />
              <!-- Course -->
              <v-expand-transition>
                <div v-if="entityDataReceivers.type === 'course' ">
                  <ws-select
                      v-if="entityDataReceivers.type === 'course'  && coursesSelectFiltered.length > 0"
                      @input="changeCourse"
                      v-model="entityDataReceivers.course_id"
                      :items="coursesSelectFiltered"
                      :error="courseError"
                      class="mt-5"

                      :label="$t('Course')"
                      width="400px"
                  />
                </div>
              </v-expand-transition>

              <v-expand-transition>
                <ws-select
                    v-if="filtersSelect.length > 0"
                    v-model="entityDataReceivers.filter"
                    class="mt-5"
                    :items="filtersSelect"
                    :label="$t('Filter')"
                    width="400px"
                    clearable
                />

              </v-expand-transition>

              <!-- Course : Payment Plan -->
              <v-expand-transition>
                <ws-select
                    v-if="entityDataReceivers.type === 'course' && paymentPlansSelect.length > 0 "
                    v-model="entityDataReceivers.payment_plan_id"
                    class="mt-5"
                    :items="paymentPlansSelect"
                    :label="$t('PaymentPlan')"
                    width="400px"
                    clearable
                />
              </v-expand-transition>
              <!-- Course : Enrollment Wave -->
              <v-expand-transition>
                <ws-select
                    v-if="entityDataReceivers.type === 'course' && enrollmentWavesSelect.length > 0 "
                    v-model="entityDataReceivers.schedule_id"
                    class="mt-5"
                    :items="enrollmentWavesSelect"
                    :label="$t('EnrollmentWave')"
                    width="400px"
                    clearable
                />
              </v-expand-transition>
              <!-- Course : Group -->
              <v-expand-transition>
                <ws-select
                    v-if="entityDataReceivers.type === 'course' && groupsSelect.length > 0 "
                    v-model="entityDataReceivers.group_id"
                    class="mt-5"
                    :items="groupsSelect"
                    :label="$t('Group')"
                    clearable
                    width="400px"
                />
              </v-expand-transition>

              <v-btn :disabled="!entityDataReceivers.type" @click="addReceiversGroup" rounded class="noCaps px-6 my-5" dark :color="wsACCENT">{{ $t('Add') }}</v-btn>

            </div>
          </div>
        </div>
      </v-expand-transition>

    </v-sheet>
    <!-- From-->
    <v-sheet
        :style="`border : 1px solid ${wsBACKGROUND}`"
        class=" mb-3 wsRoundedLight">

      <div class="pa-4 d-flex justify-space-between">
        <!-- icons and name-->
        <div class="d-flex">
          <v-icon size="20" :color="wsATTENTION" class="mr-3">mdi-account-tie</v-icon>
          <div>
            <h4 style="font-size: 16px; font-weight: 600"  >{{ $t('newsletter.email.from') }}</h4>
            <h5 class=" grey--text text--darken-3">{{ $store.state.business.selectedBusiness.name }} <span class="font-weight-light">info@westudy.ua</span>  </h5>
          </div>
        </div>
        <!-- ::  Selectors and fields ::  -->
      </div>

    </v-sheet>
    <!-- Subject-->
    <v-sheet
        :style="`border : 1px solid ${wsBACKGROUND}`"
        class=" mb-3 wsRoundedLight">

      <div class="pa-4 d-flex justify-space-between">
        <!-- icons and name-->
        <div class="d-flex ">
          <v-icon size="20" :color="newsletter.subject ? wsATTENTION : 'grey'" class="mr-3 mb-12">mdi-format-title</v-icon>
          <div>
            <h4 style="font-size: 16px; font-weight: 600"  >{{ $t('Subject') }}</h4>
            <h5 class=" grey--text text--darken-3 mt-3">{{  newsletter.subject || $t('Subject')  }}</h5>
            <h5 class="font-weight-light grey--text text--darken-3">{{ newsletter.text ||  $t('newsletter.email.text')  }}</h5>
          </div>
        </div>
        <!-- ::  Selectors and fields ::  -->
        <div>
          <v-btn @click="editSubject = !editSubject" rounded class="noCaps px-6 " text :color="wsACCENT">
            <v-icon v-if="!editSubject" class="mr-2">mdi-pencil</v-icon>
            {{ editSubject ? $t('Cancel') : $t('Edit') }}
          </v-btn>
        </div>
      </div>
      <v-expand-transition>
        <div v-if="editSubject">
          <div class="pa-4 pt-0 pl-12">

            <ws-text-field
                v-model="entityData.subject"
                class="mt-4"
                width="500"
                :label="$t('Subject')"
                clearable
            />
            <ws-text-field
                v-model="entityData.text"
                width="500"
                :label="$t('newsletter.email.text')"
                class="my-6"
                clearable
            />

            <v-btn @click="editNewsletter" rounded class="noCaps px-6 my-5" dark :color="wsACCENT">{{ $t('Save') }}</v-btn>

          </div>
        </div>
      </v-expand-transition>

    </v-sheet>
    <!-- EmailDesign-->
    <v-sheet
        :style="`border : 1px solid ${wsBACKGROUND}`"
        class=" mb-3 wsRoundedLight">

      <div class="pa-4 d-flex justify-space-between">
        <!-- icons and name-->
        <div class="d-flex">
          <v-icon size="20" :color="newsletter.newsletter_design ? wsATTENTION : 'grey' "  class="mr-3">mdi-pencil-ruler</v-icon>
          <div>
            <h4 style="font-size: 16px; font-weight: 600"  >{{ $t('Content') }}</h4>
            <h5 class="font-weight-light grey--text text--darken-3">{{ $t('newsletter.email.design_description') }}</h5>
          </div>
        </div>
        <!-- ::  Selectors and fields ::  -->
        <div>
          <v-btn @click="editDesign = !editDesign" rounded class="noCaps px-6 " text :color="wsACCENT">
            <v-icon v-if="!editDesign" class="mr-2">mdi-pencil</v-icon>
            {{ editDesign ? $t('Cancel') : $t('Edit') }}
          </v-btn>
        </div>
      </div>

    </v-sheet>

    <!-- Design Editor-->
    <v-dialog
        v-model="editDesign"
        fullscreen>
      <email-design
          @save="updateNewsletterDesign"
          v-if="editDesign"
          @close="editDesign = false"
          :uuid="newsletter.newsletter_design || null"
          :newsletter-id="newsletter.uuid"
      />
    </v-dialog>

    <!-- Overlay-->
    <v-fade-transition>
      <v-sheet v-if="editDesign" style="position:fixed; top : 0; bottom: 0;left: 0;right: 0" ></v-sheet>
    </v-fade-transition>

  </div>
</template>

<script>
import {mapActions} from "vuex";
import emailDesign from "@/components/pages/businessDashboard/businessNewsletter/email/emailConstructor/emailDesign";
export default {
  name: "emailNew",
  components : {
    emailDesign
  },
  props : {
    newsletter : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {

      coursesSelect : [],
      entityData : {},
      entityDataReceivers : {},
      editReceivers : false,
      editSubject : false,
      editDesign : false,
      courseError : false,
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' },
        // { text : this.$t('Receivers') , value : 'count' },
        { value : 'delete' , width : 50 }
      ]
    },
    selectedCourse() {
      let course = {}
      if ( this.entityDataReceivers.course_id ) {
        course = this.coursesSelect.find( el=> el.value === this.entityDataReceivers.course_id )
      }
      return course
    },

    receiversGroupTypeSelect() {
      let items = [
        { text : this.$t('newsletter.email.receiver_groups.type.all')       , value : 'all'        },
        { text : this.$t('CourseStudents')                                          , value : 'course'   },
        { text : this.$t('newsletter.email.receiver_groups.type.all_users') , value : 'all_users'  },
        { text : this.$t('newsletter.email.receiver_groups.type.clients')   , value : 'clients'    },
        { text : this.$t('Leads')   , value : 'leads'  },
        { text : this.$t('newsletter.email.receiver_groups.type.managers')  , value : 'managers'   },
        // { text : this.$t('newsletter.email.receiver_groups.type.manual_email_list') , value : 'manual_email_list'   },

      ];
      items = items.filter(el => {
       if ( this.checkFilters(el.value) ) { return false }

       if ( el.value === 'leads' && this.leadsFilters.length === 0 ) {
        return false
       }
       if ( el.value === 'course' && ( this.coursesSelectFiltered.length === 0 || this.checkFilters('all_users') || this.checkFilters('clients') ) ) {
         return false
       }

       return true

      })

      return items
    },
    coursesSelectFiltered() {

      if ( this.checkFilters('all_users') || this.checkFilters('clients') ) {
        return []
      }

      let items = this.coursesSelect
      if ( items.length === 0 ) {
        return []
      }
      items = items.filter(el=> {
        if (this.checkFilters('course' , null,el.value) ) {
          return false
        }

        if ( el.value === this.entityDataReceivers.course_id && this.courseFilters.length === 0 ) {
          return false
        }

        return true
      } )
      return items
    },

    courseFilters() {
      let items = []
      items = [
        { text : this.$t('newsletter.email.receiver_groups.filter.active_and_completed') , value : 'active_and_completed' },
        { text : this.$t('newsletter.email.receiver_groups.filter.in_process')           , value : 'in_process'           },
        { text : this.$t('newsletter.email.receiver_groups.filter.completed')            , value : 'completed'            },
        { text : this.$t('newsletter.email.receiver_groups.filter.not_active')           , value : 'not_active'           },
      ]
      if ( this.entityDataReceivers.course_id ) {
        items = items.filter( el=> !this.checkFilters('course', el.value, this.entityDataReceivers.course_id ))
      }

      return items

    },
    leadsFilters() {
      let items = []
      items = [
        { text : this.$t('lead_new_single') , value : 'new' },
        { text : this.$t('lead_contacted')  , value : 'contacted' },
        { text : this.$t('lead_no_contact') , value : 'no_contact' },
        { text : this.$t('lead_in_process') , value : 'in_process' },
        { text : this.$t('lead_success')    , value : 'success' },
        { text : this.$t('lead_refuse')     , value : 'refuse' },
      ]
      items = items.filter( el=> !this.checkFilters('leads', el.value, ))

      return items

    },
    filtersSelect() {
      let items = []
      if ( this.entityDataReceivers.type === 'course') {
        items = this.courseFilters
      }
      if ( this.entityDataReceivers.type === 'leads') {
        items = this.leadsFilters
      }
      return items
    },
    enrollmentWavesSelect() {
      let items = []
      if ( this.selectedCourse.enrollment_waves ) {
        items = this.selectedCourse.enrollment_waves
      }
      if ( items.length > 0 ) {
        items = items.filter( el=> !this.checkFilters(
            'course', this.entityDataReceivers.filter,
            this.entityDataReceivers.course_id ,
            el.value,
            this.entityDataReceivers.payment_plan_id,
        ))
      }
      return items
    },
    groupsSelect() {
      let items = []
      if ( this.selectedCourse.groups ) {
        items = this.selectedCourse.groups
        if ( this.enrollmentWavesSelect.length > 0 ) {
          if ( !this.entityDataReceivers.schedule_id ) {
            return []
          }
          items = items.filter(el => el.enrollment_wave_id === this.entityDataReceivers.schedule_id )
        } else {
          items = items.filter(el => !el.enrollment_wave_id )
        }
      }

      if ( items.length > 0 ) {
        items = items.filter( el=> !this.checkFilters(
            'course', this.entityDataReceivers.filter,
            this.entityDataReceivers.course_id ,
            this.entityDataReceivers.schedule_id,
            this.entityDataReceivers.payment_plan_id,
            el.value
        ))
      }

      return items
    },
    paymentPlansSelect() {
      let items = []
      if ( this.selectedCourse.payment_plans ) {
        items = this.selectedCourse.payment_plans
      }
      if ( items.length > 0 ) {
        items = items.filter( el=> !this.checkFilters(
            'course', this.entityDataReceivers.filter,
            this.entityDataReceivers.course_id ,
            this.entityDataReceivers.schedule,
            el.value))
      }
      return items
    },
    selectedGroup() {
      return this.checkFilters(
          this.entityDataReceivers.type,
          this.entityDataReceivers.filter,
          this.entityDataReceivers.course_id,
          this.entityDataReceivers.schedule_id,
          this.entityDataReceivers.payment_plan_id,
          this.entityDataReceivers.group_id,
      )
    },

  },
  methods : {
    ...mapActions('newsletter' , [
        'EDIT_NEWSLETTER',
        'ADD_NEWSLETTER_GROUP',
        'DELETE_NEWSLETTER_GROUP',
        'GET_COURSES_SELECT',
    ]),

    updateNewsletterDesign($event)  {
      this.newsletter.newsletter_design = $event
      this.$emit('update' , this.newsletter)
    },
    changeGroupType() {
      this.courseError = false
      delete this.entityDataReceivers.filter
      delete this.entityDataReceivers.filter_name
      delete this.entityDataReceivers.course_id
      delete this.entityDataReceivers.payment_plan_id
      delete this.entityDataReceivers.schedule_id
      this.entityDataReceivers = Object.assign({} , this.entityDataReceivers)
    },
    changeCourse() {
      this.courseError = false
      delete this.entityDataReceivers.payment_plan_id
      delete this.entityDataReceivers.schedule_id
      delete this.entityDataReceivers.group_id
      this.entityDataReceivers = Object.assign({} , this.entityDataReceivers)
    },
    async editNewsletter() {
      let result = await this.EDIT_NEWSLETTER(this.entityData)
      if ( !result ) {
        return
      }
      this.newsletter.subject = this.entityData.subject
      this.newsletter.text = this.entityData.text

      this.editSubject = false
    },
    async addReceiversGroup() {

      if ( this.entityDataReceivers.type === 'course' && !this.entityDataReceivers.course_id ) {
        this.courseError = true
        this.notify(this.$t('SelectCourse' ), 'warning')
      }

      this.entityDataReceivers.newsletter_id = this.newsletter.uuid
      this.entityDataReceivers.name = this.getGroupTypeName(this.entityDataReceivers.type)
      this.entityDataReceivers.filter_name = this.getFilterName(this.entityDataReceivers.filter)

      let result = await this.ADD_NEWSLETTER_GROUP(this.entityDataReceivers)
      if ( !result || result === true ) {
        return
      }
      this.newsletter.total_receivers = result.newsletter_total_receivers
      this.entityDataReceivers = {}
      delete result.newsletter_total_receivers
      this.newsletter.receivers_group.push(result)

    },
    async deleteReceiversGroup(item) {

      let result = await this.DELETE_NEWSLETTER_GROUP(item.uuid)
      if ( !result ) {
        return
      }
      this.newsletter.total_receivers = result.newsletter_total_receivers
      let index = this.newsletter.receivers_group.findIndex( el => el.uuid === item.uuid )
      if ( index === -1 ) {
        return
      }
      this.newsletter.receivers_group.splice(index , 1)
      this.entityDataReceivers = {}

    },

    //technical

    checkFilters(type = null,filter = null,course = null,schedule = null,paymentPlan = null,group = null) {

      type = !type ? null : type
      filter = !filter ? null : filter
      course = !course ? null : course
      schedule = !schedule ? null : schedule
      paymentPlan = !paymentPlan ? null : paymentPlan
      group = !group ? null : group

      let item = this.newsletter.receivers_group.find(el => {
        let check = true
        if ( el.type !== type) { check = false }
        if ( el.filter !== filter) { check = false }
        if ( el.course_id !== course) { check = false }
        if ( el.schedule_id !== schedule) { check = false }
        if ( el.payment_plan_id !== paymentPlan) { check = false }
        if ( el.group_id !== group) { check = false }
        return check
      })

      return item
    },

    getGroupIcon(type) {
      switch(type) {
        case 'all' : return 'mdi-account-group-outline'
        case 'all_users' : return 'mdi-account-multiple'
        case "clients" : return "mdi-card-account-details-outline"
        case "managers" : return "mdi-account-tie"
        case 'course' : return 'mdi-school-outline'
        case 'leads' : return 'mdi-card-account-phone-outline'
        default : return 'mdi-cog'
      }
    },
    getGroupTypeName(type) {

      let item = this.receiversGroupTypeSelect.find(el => el.value === type)
      return item ? item.text : ''
    },
    getFilterName(type) {
      let item = this.filtersSelect.find(el => el.value === type)
      return item ? item.text : ''
    }
  },
  async mounted() {
    this.entityData = Object.assign({} , this.newsletter)
    this.coursesSelect = await this.GET_COURSES_SELECT()
    if (this.newsletter.receivers_group && this.newsletter.receivers_group.length === 0 ) {
      this.editReceivers = true
    }
  }
}
</script>

<style scoped>

</style>